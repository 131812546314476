import CommonWrapper from "./components/CommonWrapper";
import FilteredContent from "./components/FilteredContent";
import Header from "./components/Header";
import List from "./components/List";
import ListItem from "./components/ListItem";
import ListItemDescription from "./components/ListItemDescription";

const filters = [
  { value: "definition", label: "Definition", checked: false },
  { value: "task-manifest-mgmt", label: "Task Manifest in mgmt.tools/kickstarter", checked: false },
  { value: "task-manifest-redmine", label: "Task Manifest in redmine.pro", checked: false },
  { value: "team-specific-task-manifest", label: "Team-specific Task Manifest", checked: false },
];

const IndexPage = () => (
  <CommonWrapper>
    <Header title="Some title" description="Some description" filters={filters} />
    <List type="decimal">
      {/* Have a task */}
      <ListItem title="Have a task">
        <FilteredContent filterParams={["definition"]}>
          <ListItemDescription text="In order to do the job you must have an issue for it." />
          <List type="disc">
            <ListItem description="If you have been asked to do a job but there is no issue for it you should create an issue yourself and report to your leader" />
          </List>
        </FilteredContent>
        <FilteredContent filterParams={["team-specific-task-manifest"]}>
          <List type="disc">
            <ListItem description="The branch should contain changes only for the issue, nothing more. If more changes are needed:" />
            <List type="circle">
              <ListItem description="To expand the Redmine issue description" />
              <ListItem description="To create one more Redmine issue and implement the new issue separately" />
            </List>
          </List>
        </FilteredContent>
      </ListItem>
      {/* Keep focused */}
      <ListItem title="Keep focused">
        <FilteredContent filterParams={["definition"]}>
          <ListItemDescription text="Only retain issues that you are actively working on and eliminate any others. If you encounter an issue that you cannot currently proceed with, you should:" />
        </FilteredContent>
        <List type="disc">
          <FilteredContent filterParams={["definition", "task-manifest-mgmt"]}>
            <ListItem title="Assign it to the other responsible" />
          </FilteredContent>
          <FilteredContent filterParams={["task-manifest-mgmt"]}>
            <List type="circle">
              <ListItem description="With a status from the issue flow" />
              <ListItem description="With a status More info needed if you have a question" />
            </List>
          </FilteredContent>
          <FilteredContent filterParams={["definition", "task-manifest-mgmt"]}>
            <ListItem title="Schedule it" />
          </FilteredContent>
          <FilteredContent filterParams={["task-manifest-mgmt"]}>
            <List type="circle">
              <ListItem description="To set the status Postponed and add a date to Postponed until field. At the date specified in the Postponed until the issue will be automatically turned to the previous Status." />
            </List>
          </FilteredContent>
          <FilteredContent filterParams={["definition", "task-manifest-mgmt"]}>
            <ListItem title="Make it dependent" />
          </FilteredContent>
          <FilteredContent filterParams={["task-manifest-mgmt"]}>
            <List type="circle">
              <ListItem description="To set the status Blocked and add the blocking issue to the Related issues field. Once the Blocker will be solved, the issue will be turned to the previous Status automatically." />
            </List>
          </FilteredContent>
          <FilteredContent filterParams={["definition", "task-manifest-mgmt"]}>
            <ListItem title="Push it back to the backlog" />
          </FilteredContent>
        </List>
      </ListItem>
      {/* Take care of yourself */}
      <ListItem title="Take care of yourself">
        <FilteredContent filterParams={["definition"]}>
          <List type="circle">
            <ListItem description="If you have too few issues in your personal backlog, you have to take issues into work from the project backlog yourself" />
          </List>
        </FilteredContent>
        <FilteredContent filterParams={["team-specific-task-manifest"]}>
          <ListItemDescription text="Recommended issue priorities besides Priority field" />
          <List type="disc">
            <ListItem description="Bugs basically have a higher priority than the tasks" />
            <ListItem description="Issues with a status Estimate needed, More info needed, and Review and Merging needed must be processed as soon as possible" />
            <ListItem description="Reopened issues may cause problems with rebasing if you postpone them, so please try to finish them as soon as possible" />
            <ListItem description="If you are a reviewer, you should review issues quickly. Remember your colleagues are awaiting for your review." />
          </List>
        </FilteredContent>
      </ListItem>
      {/* Single responsibility */}
      <ListItem title="Single responsibility">
        <FilteredContent filterParams={["definition"]}>
          <ListItemDescription text="Upon completion, the issue should be returned to the author for acceptance and closure" />
        </FilteredContent>
      </ListItem>
      {/* Keep issuetracker clean and clear */}
      <ListItem title="Keep issuetracker clean and clear">
        <FilteredContent filterParams={["task-manifest-redmine"]}>
          <List type="circle">
            <ListItem description="All persons involved in task development should be defined in the Checklist item in advance" />
            <ListItem description="The Checklist is preferred over the Description" />
            <ListItem description="The user who adds a note about task changing must correct Description, Checklist and Subject to make them relevant. New users shouldn't need to read notes" />
          </List>
        </FilteredContent>
        <FilteredContent filterParams={["team-specific-task-manifest"]}>
          <List type="disc">
            <ListItem description="If the Estimated time for the issue exceeds 12 hours, then the issue should be split into parts, be described in the Checklist, and, in addition to Estimated time of the issue, be estimated for each Checklist item." />
            <ListItem description="When assigning technologically complex tasks initiated by the developer to the customer, the user must fill in the Impact field and note concrete numbers that reveal the need of the task" />
          </List>
        </FilteredContent>
      </ListItem>
      {/* Make sure the task in't lost */}
      <ListItem title="Make sure the task isn't lost">
        <FilteredContent filterParams={["task-manifest-mgmt"]}>
          <ListItemDescription text="Set field Due date to the expected date when you want to follow up on the task so that Kickstarter can return it back to you" />
        </FilteredContent>
      </ListItem>
    </List>
  </CommonWrapper>
);

export default IndexPage;
