import { useSearchParams } from "react-router-dom";

const Header = ({ title, description, filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (e) => {
    const { value, checked } = e.target;
    searchParams.set(value, checked.toString());

    setSearchParams(searchParams);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    searchParams.forEach((p, par) => {
      searchParams.set(par, "false");
    });
    filters.forEach((par) => (par.checked = false));

    setSearchParams(searchParams);
  };

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-8">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">{title}</h1>
        {description && <p className="mt-4 max-w-xl text-sm text-gray-700">{description}</p>}
      </div>

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="bg-white border-b border-gray-200 pb-4">
          <div className="max-w-7xl mx-auto flex flex-col gap-4">
            <div className="inline-block text-sm font-medium text-gray-700">Filters</div>

            <div className="block">
              <div className="flow-root">
                <form className="space-y-4">
                  {filters.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center">
                      <input
                        id={`filter-${option.value}-${optionIdx}`}
                        name={`${option.value}[]`}
                        defaultValue={option.value}
                        type="checkbox"
                        checked={"true" == searchParams.get(option.value)}
                        onChange={handleChange}
                        className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                      />
                      <label
                        htmlFor={`filter-${option.value}-${optionIdx}`}
                        className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                  <button
                    type="reset"
                    className="px-4 py-2 text-base font-medium text-blue-800 bg-blue-100 hover:bg-blue-200 inline-flex items-center border border-transparent rounded-md"
                    onClick={HandleSubmit}
                  >
                    Clear all
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Header;
