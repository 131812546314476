const ListItem = ({ title, description, children }) => (
  <li className="font-semibold">
    {title}
    {title && <br />}
    {description && <p className="font-normal">{description}</p>}
    {children}
  </li>
);

export default ListItem;
