import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import IndexPage from "./Index";

const router = createBrowserRouter(createRoutesFromElements(<Route path="/" element={<IndexPage />}></Route>));

const App = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default App
