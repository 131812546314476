import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const FilteredContent = ({ filterParams, children }) => {
  const [searchParams] = useSearchParams();
  const [isDisplay, setIsDisplay] = useState(false);

  // if one of param = true => we display data
  useEffect(() => {
    for (const par of filterParams) {
      if (searchParams.get(par) && searchParams.get(par) == true.toString()) {
        setIsDisplay(true);
        break;
      } else setIsDisplay(false);
    }
  }, [searchParams]);

  return <>{isDisplay && children}</>;
};

export default FilteredContent;
